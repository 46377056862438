export const formColData1 = [
  { label: '支付订单号', placeholder: '请输入支付订单号', prop: 'paymentOrderCode' },
  { label: '分销商名称', placeholder: '输入分销商名称', prop: 'nickName' },
  { label: '提交时间', prop: 'createTime', slotName: 'createTimeSlot' },
  { prop: 'button' }
]
export const formColData2 = [
  { label: '支付订单号', placeholder: '请输入支付订单号', prop: 'paymentOrderCode' },
  { label: '订单号', placeholder: '请输入订单号', prop: 'orderCodes' },
  { label: '分销商名称', placeholder: '输入分销商名称', prop: 'nickName' },
  { label: '付款方式', placeholder: '选择付款方式', prop: 'paymentType' },
  { label: '付款时间', prop: 'paymentTime' },
  { prop: 'button' }
]
export const formColData3 = [{prop: 'back'},{ label: '状态', prop: 'priceStatus' }]
